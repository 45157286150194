// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyBT0eYd4eo7MAn46sqDBVE62OosGAq7Lvs",
  authDomain: "fitvibe-aa239.firebaseapp.com",
  projectId: "fitvibe-aa239",
  storageBucket: "fitvibe-aa239.appspot.com",
  messagingSenderId: "245005803309",
  appId: "1:245005803309:web:78ee3e00fc50ffb0763da4",
  measurementId: "G-T7B2P6XSY8"
};
